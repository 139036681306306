import { VFC, useCallback, useMemo, useState } from "react";
import { Empty, Select, SelectProps, Spin } from "antd";
import { DefaultOptionType } from "antd/lib/select";

import { useStoreList } from "../queries/stores";
import { useDebounce } from "../hooks";
import { StoreListPayload } from "../queries/api/stores";
import { Store } from "../queries/api/types";

interface SelectStoreProps extends Omit<SelectProps, "options" | "onSearch"> {
    additionalOptions?: DefaultOptionType[];
    queryPayload?: StoreListPayload;
    disabledStores?: Array<Store["id"]>;
    excludedStores?: Array<Store["id"]>;
}

const SelectStore: VFC<SelectStoreProps> = ({
    additionalOptions = [],
    queryPayload,
    disabledStores = [],
    excludedStores = [],
    ...props
}) => {
    const [query, setQuery] = useState<string | undefined>(undefined);
    const search = useDebounce(query, 500);
    const { data: storeListData, isLoading } = useStoreList({
        pageSize: 20,
        search,
        ...queryPayload,
    });
    const options: SelectProps["options"] = useMemo(() => {
        return (storeListData?.items || [])
            .filter(({ id }) => !excludedStores.includes(id))
            .map(({ id, reference, name }) => ({
                label: `${reference} - ${name}`,
                value: id,
                disabled: disabledStores.includes(id),
            }));
    }, [disabledStores, excludedStores, storeListData?.items]);

    const onSelect = useCallback(() => {
        setQuery(undefined);
    }, []);

    return (
        <Select
            options={[...additionalOptions, ...options]}
            showSearch
            onSearch={(query) => setQuery(query)}
            notFoundContent={isLoading ? <Spin size="small" /> : <Empty />}
            filterOption={false}
            onSelect={onSelect}
            {...props}
        />
    );
};

export default SelectStore;
