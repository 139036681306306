/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 12">
        <path
            fill="gray"
            fillRule="evenodd"
            d="M14.653.407c.194-.252.504-.407.839-.407h1.639c.693 0 1.128.763.697 1.326l-.002.003-7.703 10.123c-.555.732-1.691.73-2.247.002L.167 1.322C-.255.762.174 0 .87 0h1.639c.334 0 .644.155.838.407L3.35.41 9 7.835z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgChevron: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-chevron", props.className)} />
));

export default SvgChevron;
